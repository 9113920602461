import 'whatwg-fetch';
import IDLogix from './lib';

let instance;
const idlogix = (fn, ...args) => {
  if (fn === 'init') {
    if (!instance) {
      instance = new IDLogix(...args);
    } else {
      throw new Error('id|Logix has already been initialized.');
    }
  } else if (fn === 'destroy') {
    if (instance) {
      instance.destroy();
      instance = undefined;
    }
  } else {
    if (!instance) throw new Error('No id|Logix instance has been created. Ensure `idlogix(\'init\')` was run.');
    instance.run(fn, ...args);
  }
};

const execute = (stack) => {
  for (let i = 0; i < stack.length; i += 1) {
    const args = stack[i];
    idlogix(...args);
  }
};

const { IDLogixQueue } = window;
if (Array.isArray(IDLogixQueue)) {
  // Execute the init command first.
  const first = [];
  const next = [];
  for (let i = 0; i < IDLogixQueue.length; i += 1) {
    const args = IDLogixQueue[i];
    if (args[0] === 'init') {
      first.push(args);
    } else {
      next.push(args);
    }
  }
  execute(first);
  execute(next);
}

window.idlogix = idlogix;
