import gql from 'graphql-tag';
import pkg from '../../package.json';
import components from '../components/public';
import Logger from './logger';

class IDLogix {
  constructor({ debug, namespace, graphqlUri } = {}) {
    this.name = pkg.name;
    this.version = pkg.version;
    this.logger = new Logger({ enabled: debug });

    if (!namespace) throw new Error('The id|Logix workspace `namespace` must be provided.');
    if (!graphqlUri) throw new Error('The id|Logix `graphqlUri` must be provided.');

    this.namespace = namespace;
    this.graphqlUri = graphqlUri;

    this.apolloProvider = undefined;
    this.vue = undefined;
    this.initPromise = undefined;

    this.logger.log('library loaded', { name: this.name, version: this.version });
  }

  async init() {
    const { error } = console;
    const { defaultClient: apollo } = await this.loadApolloProvider();
    try {
      const mutation = gql`
        mutation Boot {
          boot @client
        }
      `;
      await apollo.mutate({ mutation });
    } catch (e) {
      // @todo how should errors be logged or handled.
      // for instance, if an expired login token is passed or a general error occurrs?
      error(e);
    }
  }

  /**
   * This is the public facing API. All browser commands should be executed from this method.
   *
   * @param {string} name The function name to run.
   * @param  {...any} args The function arguments to pass.
   */
  async run(name, ...args) {
    const commands = {
      loadComponent: this.loadPublicComponent.bind(this),
    };
    const command = commands[name];
    if (!command) throw new Error(`No command was found for ${name}.`);
    if (!this.initPromise) this.initPromise = this.init();
    await this.initPromise;
    return command(...args);
  }

  /**
   *
   * @param {object} params
   * @param {string} name The component name.
   * @param {string|HTMLElement} params.el The element to attach the component to.
   *                                       Note: the element will be replaced.
   * @param {object} [params.props] The props to pass to the component.
   * @param {object} [params.on] Events to attach to.
   */
  async loadPublicComponent({
    name,
    el,
    props,
    on,
  } = {}) {
    if (!components[name]) throw new Error(`No idLogix component found for '${name}'`);
    await this.loadComponent({
      promise: components[name](),
      el,
      props,
      on,
    });
    this.logger.log(`component '${name}' loaded`, { el, props, on });
  }

  /**
   * Loads a component with the provided import promise.
   */
  async loadComponent({
    promise,
    el,
    props,
    on,
  } = {}) {
    const [Component, Vue, apolloProvider] = await Promise.all([
      IDLogix.component(promise),
      this.loadVue(),
      this.loadApolloProvider(),
    ]);

    // eslint-disable-next-line no-new
    new Vue({
      el,
      apolloProvider,
      render: (h) => h(Component, { props, on }),
    });
  }

  async loadVue() {
    const [Vue, VueApollo] = await Promise.all([
      IDLogix.component(import(/* webpackChunkName: "vue" */ 'vue')),
      IDLogix.component(import(/* webpackChunkName: "vue-apollo" */ 'vue-apollo')),
    ]);
    if (!this.vue) {
      Vue.use(VueApollo);
      Vue.prototype.$logger = this.logger;
      this.vue = Vue;
    }
    return this.vue;
  }

  /**
   * Creates a single instance of the Apollo provider and client.
   */
  async loadApolloProvider() {
    const { default: createProvider } = await import(/* webpackChunkName: "apollo-provider" */ '../apollo/create-provider');
    if (!this.apolloProvider) {
      const opts = {
        uri: this.graphqlUri,
        namespace: this.namespace,
      };
      this.apolloProvider = createProvider(opts);
      this.logger.log('apollo provider created', opts);
    }
    return this.apolloProvider;
  }

  /**
   * Imports a dynamic component.
   *
   * @param {Promise} promise The dynamic import promise.
   */
  static async component(promise) {
    const { default: Component } = await promise;
    return Component;
  }
}

export default IDLogix;
